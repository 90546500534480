<template>
  <div data-layout="token-designer/tokenomics">
    <NuxtLoadingIndicator />
    <NuxtLayout name="dashboard">
      <template #header>
        <div class="flex gap-4 items-center">
          <img src="/app/illustrations/tokenomics-v2-icon.svg" alt="Tokenomics Icon" width="32" height="32">
          <div class="text-xl font-semibold flex-shrink-0">
            Tokenomics
          </div>
        </div>
      </template>
      <slot />
    </NuxtLayout>
  </div>
</template>
